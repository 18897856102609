import React from "react";
import HeartIcon from "../../assets/svg/HeartIcon";

export default function CasinoCard({ data }) {
  return (
    <a href={data.href || "#"} className="mb-4">
      <div className="shadow rounded position-relative">
        <img src={data?.cover?.src} alt={data?.cover?.alt || ""} />
        {/* <span className="like-btn">
          <HeartIcon />
        </span> */}
      </div>
    </a>
  );
}
