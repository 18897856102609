import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import BtmFootInplay from "../assets/images/icons/home/btmFootInplay.png";
import BtmFootSports from "../assets/images/icons/footer/mini_games.gif";
import BtmFootHome from "../assets/images/icons/home/btmFootHome.png";
import BtmFootCasino from "../assets/images/icons/home/btmFootCasino.png";
import BtmFootProfile from "../assets/images/icons/home/btmFootProfile.png";
import { useDispatch, useSelector } from "react-redux";
import { handleRightSideBar } from "../redux/app/actions";
import ModalWrapper from "./ModalWrapper";

export default function BottomNav() {
  const dispatch = useDispatch();
  const { showRightSideBar } = useSelector((state) => state.app);
  const [showAuthModals, setShowAuthModals] = useState(false);

  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const { isAuth } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const footerPages = [
    "/sports",
    "/sportsbook",
    "/casino/evolution",
    "/casino/ezugi",
    "/casino/spribe/aviator",
    "/casino/vivo",
    "/casino/Casino",
    "/casino/qtech",
    "/casino/worldcasino",
    "/Slots",
    "/spribe",
  ];
  useEffect(() => {
    const matchedValue = footerPages.find((f) => {
      return location?.pathname === f;
    });

    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  return (
    <div>
      <footer className="d-lg-none">
        <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100">
          <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
            <li className="home">
              <a
                className={
                  activePage === "/sports" || activePage === "/sports"
                    ? "active"
                    : ""
                }
                href="/sports"
              >
                <div className="imgBox">
                  <img src={BtmFootHome} alt="footer nav icon" />
                </div>
                <span>Home</span>
              </a>
            </li>
            <li className="inplay">
              <a
                className={
                  activePage === "/sportsbook" || activePage === "public-inplay"
                    ? "active"
                    : ""
                }
                href={isAuth ? "/sportsbook/Inplay" : "/public-inplay"}
              >
                <div className="imgBox">
                  <img src={BtmFootInplay} alt="footer nav icon" />
                </div>
                <span>In play</span>
              </a>
            </li>
            <li className="sports">
              <div
                className={
                  activePage === "/spribe" || activePage === "spribe"
                    ? "active"
                    : "menuDiv"
                }
                onClick={() => {
                  isAuth ? navigate("/spribe") : setShowAuthModals(true);
                  // console.log("SideBar", showRightSideBar);
                }}
              >
                <div className="imgBox">
                  <img src={BtmFootSports} alt="footer nav icon" />
                </div>
                <span>Mini Games</span>
              </div>
            </li>

            {/* <li className="casino">
              <a
                className={activePage === "live-dealer" ? "active" : ""}
                href={isAuth ? "/live-dealer" : "/live-dealer-public"}
              >
                <div className="imgBox">
                  <img src={BtmFootCasino} alt="footer nav icon" />
                </div>
                <span>Casino</span>
              </a>
            </li> */}
            <li
              className="profile"
              onClick={() => {
                isAuth
                  ? dispatch(handleRightSideBar(!showRightSideBar))
                  : setShowAuthModals(true);
                // console.log("SideBar", showRightSideBar);
              }}
            >
              <div
                className={activePage === "profile" ? "active" : "menuDiv"}
                // href="/profile"
              >
                <div className="imgBox">
                  <img src={BtmFootProfile} alt="footer nav icon" />
                </div>
                <span>Menu</span>
              </div>
            </li>
          </ul>
        </div>
        {showAuthModals && (
          <ModalWrapper
            defaultShow={"login"}
            setShowAuthModals={setShowAuthModals}
          />
        )}
      </footer>
    </div>
  );
}
