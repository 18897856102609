export const wcocasinotab = {
  roulettetab: {
    SpeedRoulette: {
      href: "/casino/ezugi/SpeedRoulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino01.png", alt: "" },
    },
    DiamondRoulette: {
      href: "/casino/ezugi/DiamondRoulette",
      code: "221003",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino02.png", alt: "" },
    },
    NamasteRoulette: {
      href: "/casino/ezugi/NamasteRoulette",
      code: "221005",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino03.png", alt: "" },
    },
    TurkishRoulette: {
      href: "/casino/ezugi/TurkishRoulette",
      code: "5501000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino04.png", alt: "" },
    },
    AutoRoulette: {
      href: "/casino/ezugi/AutoRoulette",
      code: "5001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino05.png", alt: "" },
    },
    SpeedAutoRoulette: {
      href: "/casino/ezugi/SpeedAutoRoulette",
      code: "221002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino06.png", alt: "" },
    },
    OracleRoulette2: {
      href: "/casino/ezugi/OracleRoulette2",
      code: "611001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino07.png", alt: "" },
    },
    PortomasoRoulette1: {
      href: "/casino/ezugi/PortomasoRoulette1",
      code: "611004",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino08.png", alt: "" },
    },
    ItalianRoulette: {
      href: "/casino/ezugi/ItalianRoulette",
      code: "1000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino09.png", alt: "" },
    },
    SpanishRoulette: {
      href: "/casino/ezugi/SpanishRoulette",
      code: "411000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino010.png", alt: "" },
    },
    RussianRoulette: {
      href: "/casino/ezugi/RussianRoulette",
      code: "601000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino011.png", alt: "" },
    },
    FiestaRoulette: {
      href: "/casino/ezugi/FiestaRoulette",
      code: "431001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino012.png", alt: "" },
    },
    PrestigeAutoRoulette: {
      href: "/casino/ezugi/PrestigeAutoRoulette",
      code: "221004",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino013.png", alt: "" },
    },
    HandCasinoHoldem: {
      href: "/casino/evolution/HandCasinoHoldem",
      code: 507000,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino014.png", alt: "" },
    },
    LightingRoulette: {
      href: "/casino/evolution/LightingRoulette",
      code: 507000,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino015.png", alt: "" },
    },
    TurkceRoulette: {
      href: "/casino/evolution/TurkceRoulette",
      code: 507000,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino016.png", alt: "" },
    },
    TurkishsRoulette: {
      href: "/casino/evolution/TurkishsRoulette",
      code: 507000,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino017.png", alt: "" },
    },
    TurkisheRoulette: {
      href: "/casino/evolution/TurkisheRoulette",
      code: 507000,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino018.png", alt: "" },
    },
    MarinaRoulette: {
      href: "/casino/evolution/MarinaRoulette",
      code: 507000,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino019.png", alt: "" },
    },
    MarinaSpeedRoulette: {
      href: "/casino/evolution/MarinaSpeedRoulette",
      code: 507000,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino020.png", alt: "" },
    },
    TurkishcRoulette: {
      href: "/casino/evolution/TurkishcRoulette",
      code: 507000,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino021.png", alt: "" },
    },
    xxxtremeRoulette: {
      href: "/casino/evolution/xxxtremeRoulette",
      code: 507000,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino022.png", alt: "" },
    },
    DragonRoulette: {
      href: "/casino/evolution/DragonRoulette",
      code: 507000,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino023.png", alt: "" },
    },
    AutoSpeedRoulette: {
      href: "/casino/evolution/AutoSpeedRoulette",
      code: 507000,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino024.png", alt: "" },
    },
    LiveRoulette: {
      href: "/casino/evolution/LiveRoulette",
      code: 507000,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino025.png", alt: "" },
    },
  },
  baccarattab: {
    Baccaratpro: {
      href: "/casino/ezugi/Baccaratpro",
      code: "100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino027.png", alt: "" },
    },
    Nocommissiobaccarat: {
      href: "/casino/ezugi/Nocommissiobaccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino028.png", alt: "" },
    },
    Knockoutbaccarat: {
      href: "/casino/ezugi/Knockoutbaccarat",
      code: "120",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino029.png", alt: "" },
    },
    Super6baccarat: {
      href: "/casino/ezugi/Super6baccarat",
      code: "32100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino030.png", alt: "" },
    },
    Marinabaccarat: {
      href: "/casino/ezugi/Marinabaccarat",
      code: "130",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino031.png", alt: "" },
    },

    Marina02baccarat: {
      href: "/casino/ezugi/Marina02baccarat",
      code: "32101",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino032.png", alt: "" },
    },
    Salsabaccarat: {
      href: "/casino/ezugi/Salsabaccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino033.png", alt: "" },
    },
    Baccarat: {
      href: "/casino/ezugi/Baccarat",
      code: "106",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino034.png", alt: "" },
    },
    // Baccaratpro02: {
    //   href: "/casino/ezugi/Baccaratpro02",
    //   code: "101",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/baccarat/casino028.png", alt: "" },
    // },
    // SpeedBaccarat: {
    //   href: "/casino/ezugi/SpeedBaccarat",
    //   code: "105",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/baccarat/casino029.png", alt: "" },
    // },
    // Marina03baccarat: {
    //   href: "/casino/ezugi/Marina03baccarat",
    //   code: "32103",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/baccarat/baccarat010.png", alt: "" },
    // },
  },
  AndarBahartab: {
    AndarBaharLive: {
      href: "/casino/ezugi/AndarBaharLive",
      code: "228000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/casino030.png", alt: "" },
    },
    AndarBaharMarnia: {
      href: "/casino/ezugi/AndarBaharMarnia",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/casino032.png", alt: "" },
    },
  },
  TeenPattitab: {
    TeenPatti01: {
      href: "/casino/ezugi/TeenPatti01",
      code: "227100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/casino031.png", alt: "" },
    },
    TeenPatti02: {
      href: "/casino/ezugi/TeenPatti02",
      code: "227101",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/casino034.png", alt: "" },
    },
    TeenPatti03: {
      href: "/casino/ezugi/TeenPatti03",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/casino033.png", alt: "" },
    },
  },
  Blackjacktab: {
    Blackjack: {
      href: "/casino/ezugi/Blackjack",
      code: "201",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino039.png", alt: "" },
    },
    GoldBlackjack: {
      href: "/casino/ezugi/GoldBlackjack",
      code: "1",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino040.png", alt: "" },
    },
    BlackjackPro: {
      href: "/casino/ezugi/BlackjackPro",
      code: "224",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino041.png", alt: "" },
    },
    VIPBlackjack: {
      href: "/casino/ezugi/VIPBlackjack",
      code: "225",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino042.png", alt: "" },
    },
    BlackjackAutoSplit: {
      href: "/casino/ezugi/BlackjackAutoSplit",
      code: "226",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino043.png", alt: "" },
    },
    TurkceBlackjack: {
      href: "/casino/ezugi/TurkceBlackjack",
      code: "501",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino044.png", alt: "" },
    },
    LiveBlackjack: {
      href: "/casino/ezugi/LiveBlackjack",
      code: "221",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino045.png", alt: "" },
    },
    RumbaBlackjack: {
      href: "/casino/ezugi/RumbaBlackjack",
      code: "411",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino046.png", alt: "" },
    },
    DiamondBlackjack: {
      href: "/casino/ezugi/DiamondBlackjack",
      code: "225",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino047.png", alt: "" },
    },
    Rumba02Blackjack: {
      href: "/casino/ezugi/Rumba02Blackjack",
      code: "414",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino048.png", alt: "" },
    },
    VipBlackjack: {
      href: "/casino/ezugi/VipBlackjack",
      code: "224",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino049.png", alt: "" },
    },
    Vip02Blackjack: {
      href: "/casino/ezugi/Vip02Blackjack",
      code: "226",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino050.png", alt: "" },
    },
    TurkishBlackjack: {
      href: "/casino/ezugi/TurkishBlackjack",
      code: "501",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino051.png", alt: "" },
    },
    BlackjackLive: {
      href: "/casino/ezugi/BlackjackLive",
      code: "422",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino052.png", alt: "" },
    },
    BlackjackTurkce: {
      href: "/casino/ezugi/BlackjackTurkce",
      code: "5",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino053.png", alt: "" },
    },
    PlatinumBlackjack: {
      href: "/casino/ezugi/PlatinumBlackjack",
      code: "5051",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino054.png", alt: "" },
    },
    ItalianBlackjack: {
      href: "/casino/ezugi/ItalianBlackjack",
      code: "5051",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino055.png", alt: "" },
    },
  },
  thirtytwoCardtab: {
    thirtytwoCards: {
      href: "/casino/ezugi/thirtytwoCards",
      code: "228002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/card/casino057.png", alt: "" },
    },
  },
  sevenLuckytab: {
    thirtytwoCards: {
      href: "/casino/ezugi/thirtytwoCards",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/lucky7/casino056.png", alt: "" },
    },
  },
  sicbotab: {
    sicbodice01: {
      href: "/casino/ezugi/sicbodice01",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/casino058.png", alt: "" },
    },
    sicbodice02: {
      href: "/casino/ezugi/sicbodice02",
      code: "224100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/casino059.png", alt: "" },
    },
    sicbodice03: {
      href: "/casino/ezugi/sicbodice03",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/casino060.png", alt: "" },
    },
    sicbodice04: {
      href: "/casino/ezugi/sicbodice04",
      code: "224100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/casino062.png", alt: "" },
    },
  },
  BetOnNumber: {
    BetOnNumber01: {
      href: "/casino/ezugi/BetOnNumber01",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/games/casino035.png", alt: "" },
    },
    BetOnNumber02: {
      href: "/casino/ezugi/BetOnNumber02",
      code: "224100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/games/casino036.png", alt: "" },
    },
    BetOnNumber03: {
      href: "/casino/ezugi/BetOnNumber03",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/games/casino037.png", alt: "" },
    },
    BetOnNumber04: {
      href: "/casino/ezugi/BetOnNumber04",
      code: "224100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/games/casino038.png", alt: "" },
    },
  },
  spribe: {
    href: "/casino/sprie",
    code: "",
    provider: "spribe",
    homeUrl: "",
    games: {
      mines: {
        href: "/casino/spribe/mines",
        code: "mines",
        casino: "spribe",
        provider: "mines",
        homeUrl: "",
        cover: { src: "./images/spribe/02.jpg", alt: "" },
      },
      goal: {
        href: "/casino/spribe/goal",
        code: "goal",
        casino: "spribe",
        provider: "goal",
        homeUrl: "",
        cover: { src: "./images/spribe/06.png", alt: "" },
      },
      dice: {
        href: "/casino/spribe/dice",
        code: "dice",
        casino: "spribe",
        provider: "dice",
        homeUrl: "",
        cover: { src: "./images/spribe/07.jpg", alt: "" },
      },
      aviator: {
        href: "/casino/spribe/aviator",
        code: "aviator",
        casino: "spribe",
        provider: "aviator",
        homeUrl: "",
        cover: { src: "./images/spribe/03.png", alt: "" },
      },
      plinko: {
        href: "/casino/spribe/plinko",
        code: "plinko",
        casino: "spribe",
        provider: "plinko",
        homeUrl: "",
        cover: { src: "./images/spribe/08.jpg", alt: "" },
      },
      miniroulette: {
        href: "/casino/spribe/miniroulette",
        code: "mini-roulette",
        casino: "spribe",
        provider: "mini-roulette",
        homeUrl: "",
        cover: { src: "./images/spribe/04.png", alt: "" },
      },
      hilo: {
        href: "/casino/spribe/hilo",
        code: "hi-lo",
        casino: "spribe",
        provider: "hi-lo",
        homeUrl: "",
        cover: { src: "./images/spribe/05.png", alt: "" },
      },
    },
  },
  popularGames: {
    lucky7: {
      href: "/casino/ezugi/lucky7",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/01.jpg", alt: "" },
    },
    Monopoly: {
      href: "/casino/ezugi/Monopoly",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/02.jpg", alt: "" },
    },

    Dragontiger: {
      href: "/casino/ezugi/Dragontiger",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/03.jpg", alt: "" },
    },
    Speed: {
      href: "/casino/ezugi/Speed",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/04.jpg", alt: "" },
    },
    speedcard: {
      href: "/casino/ezugi/speedcard",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/05.jpg", alt: "" },
    },
    Miniroulette2: {
      href: "/casino/ezugi/Miniroulette2",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/06.jpg", alt: "" },
    },
    Hilo2: {
      href: "/casino/ezugi/Hilo2",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/07.jpg", alt: "" },
    },
  },
  slotsgames: {
    twinspin: {
      href: "/casino/ezugi/twinspin",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/01.jpg", alt: "" },
    },
    gonzoquest: {
      href: "/casino/ezugi/gonzoquest",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/02.jpg", alt: "" },
    },

    asgardianstones: {
      href: "/casino/ezugi/asgardianstones",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/03.jpg", alt: "" },
    },
    secrets: {
      href: "/casino/ezugi/secrets",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/04.jpg", alt: "" },
    },
    grandwheel: {
      href: "/casino/ezugi/grandwheel",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/05.jpg", alt: "" },
    },
  },
  Proivdergames: {
    Evolution: {
      href: "/casino/Evolution",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/provider/01.png", alt: "" },
    },
    ezugi: {
      href: "/casino/ezugi",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/provider/02.png", alt: "" },
    },

    supernova: {
      href: "/casino/supernova",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/provider/03.png", alt: "" },
    },
    xpg: {
      href: "/casino/xpg",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/provider/04.png", alt: "" },
    },
  },
  PaymentMethod: {
    netbanking: {
      href: "/casino/Evolution",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/payments/netbanking.svg", alt: "" },
    },
    upi: {
      href: "/casino/ezugi",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/payments/upi.svg", alt: "" },
    },

    visa: {
      href: "/casino/supernova",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/payments/visa.svg", alt: "" },
    },
    mastercard: {
      href: "/casino/xpg",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/payments/mastercard_white.svg", alt: "" },
    },
    paytm: {
      href: "/casino/xpg",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/payments/paytm.svg", alt: "" },
    },
  },

  spribe: {
    href: "/casino/sprie",
    code: "",
    provider: "spribe",
    homeUrl: "",
    games: {
      mines: {
        href: "/casino/spribe/mines",
        code: "mines",
        casino: "spribe",
        provider: "mines",
        homeUrl: "",
        cover: { src: "./images/spribe/02.jpg", alt: "" },
      },
      goal: {
        href: "/casino/spribe/goal",
        code: "goal",
        casino: "spribe",
        provider: "goal",
        homeUrl: "",
        cover: { src: "./images/spribe/06.png", alt: "" },
      },
      dice: {
        href: "/casino/spribe/dice",
        code: "dice",
        casino: "spribe",
        provider: "dice",
        homeUrl: "",
        cover: { src: "./images/spribe/07.jpg", alt: "" },
      },
      aviator: {
        href: "/casino/spribe/aviator",
        code: "aviator",
        casino: "spribe",
        provider: "aviator",
        homeUrl: "",
        cover: { src: "./images/spribe/03.png", alt: "" },
      },
      plinko: {
        href: "/casino/spribe/plinko",
        code: "plinko",
        casino: "spribe",
        provider: "plinko",
        homeUrl: "",
        cover: { src: "./images/spribe/08.jpg", alt: "" },
      },
      miniroulette: {
        href: "/casino/spribe/miniroulette",
        code: "mini-roulette",
        casino: "spribe",
        provider: "mini-roulette",
        homeUrl: "",
        cover: { src: "./images/spribe/04.png", alt: "" },
      },
      hilo: {
        href: "/casino/spribe/hilo",
        code: "hi-lo",
        casino: "spribe",
        provider: "hi-lo",
        homeUrl: "",
        cover: { src: "./images/spribe/05.png", alt: "" },
      },
    },
  },
};
