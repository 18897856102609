import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import { logoutUser } from "../redux/auth/actions";

import HomeIcon from "../assets/images/icons/sidebar/Home.png";
import CasinoIcon from "../assets/images/games-icon/casino-icon.png";
import InplayIcon from "../assets/images/icons/sidebar/InPlay.png";
import AvitorImg from "../assets/images/icons/aviator.png";

import CricketIcon from "../assets/images/icons/sidebar/Cricket.png";
import SoccerIcon from "../assets/images/icons/sidebar/Soccer.png";
import TennisIcon from "../assets/images/icons/sidebar/Tennis.png";
import GolfIcon from "../assets/images/games-icon/golf-icon.png";
import RugbyIcon from "../assets/images/games-icon/rugby-icon.png";
import BoxingIcon from "../assets/images/games-icon/boxing-icon.png";
import horseracingIcon from "../assets/images/icons/sidebar/Horse.png";
import EsportsIcon from "../assets/images/games-icon/esports-icon.png";
import VolleyballIcon from "../assets/images/games-icon/volleyball-icon.png";
import CyclingIcon from "../assets/images/games-icon/cycling-icon.png";
import SnookerIcon from "../assets/images/games-icon/snooker-icon.png";
import BaseballIcon from "../assets/images/games-icon/baseball-icon.png";
import NetballIcon from "../assets/images/games-icon/netball-icon.png";
import BasketballIcon from "../assets/images/games-icon/basketball-icon.png";
import IceHockeyIcon from "../assets/images/games-icon/icehockey-icon.png";
import HandballIcon from "../assets/images/games-icon/handball-icon.png";
import DartsIcon from "../assets/images/games-icon/darts-icon.png";
import GreyhoundIcon from "../assets/images/icons/sidebar/greyhound.png";
import PoliticsIcon from "../assets/images/games-icon/politics-icon.png";
import WCIcon from "../assets/images/icons/sidebar/WorldCasino.png";
import EvolutionIcon from "../assets/images/icons/sidebar/Evolution.png";
import EzugiIcon from "../assets/images/icons/sidebar/Ezugi.png";
import LCIcon from "../assets/images/icons/sidebar/LiveCasino.png";
import SlotsIcon from "../assets/images/icons/sidebar/Slot.png";
import KabbadiIcon from "../assets/images/icons/sidebar/Kabaddi.png";

import WalletIcon from "../assets/images/WalletIcon.png";
import UserIcon from "../assets/images/user-icon.svg";
import BetIcon from "../assets/images/BetIcon.png";
import AcStatement from "../assets/images/AcStatement.png";
import LogoutIconImg from "../assets/images/logout.png";
import BonusImg from "../assets/images/bonus.png";
import refferal from "../assets/images/refferal.png";

import Cross from "../assets/images/icons/close.png";

import MoonLight from "../assets/images/moon.svg";
import SunLight from "../assets/images/sunny.svg";
import { handleLeftSideBar, handleRightSideBar } from "../redux/app/actions";
import ModalWrapper from "./ModalWrapper";

const MobLeftbar = () => {
  const [showAuthModals, setShowAuthModals] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sportsData } = useSelector((state) => state.sports);
  const { isAuth } = useSelector((state) => state.auth);
  const { showRightSideBar } = useSelector((state) => state.app);
  const { showLeftSideBar } = useSelector((state) => state.app);

  const handleEventClick = (event) => {
    navigate(
      `/detail-page/${event?.marketSlug}/${event?.matchid}/${event?.marketid}/${event?.SportId}`
    );
  };

  const { user, balance, wallet } = useSelector((state) => state.auth);

  const [isClassAdded, setIsClassAdded] = useState(false);
  useEffect(() => {
    const isClassAddedLocalStorage = localStorage.getItem("isClassAdded");
    if (isClassAddedLocalStorage) {
      setIsClassAdded(true);
      document.body.classList.add("light-theme");
    }
  }, []);
  const toggleClass = () => {
    if (isClassAdded) {
      setIsClassAdded(false);
      localStorage.removeItem("isClassAdded");
      document.body.classList.remove("light-theme");
    } else {
      setIsClassAdded(true);
      localStorage.setItem("isClassAdded", true);
      document.body.classList.add("light-theme");
    }
  };

  return (
    <div className="mobleftbarPage">
      <div className="leftbarSec">
        {isAuth && <></>}
        <ul>
          <li className="games_link">
            <div className="d-flex justify-content-between HeadingImg align-items-center">
              <h6>Popular</h6>
              <img
                src={Cross}
                alt="cross"
                onClick={() => {
                  dispatch(handleLeftSideBar(!showLeftSideBar));
                  // console.log("SideBar", showRightSideBar);
                }}
              />
            </div>
            <ul>
              <li>
                <div
                  // onClick={() => {
                  //   navigate("/sports");
                  // }}
                  onClick={() => {
                    isAuth
                      ? (window.location.href = "/sports")
                      : setShowAuthModals(true);
                    // console.log("SideBar", showRightSideBar);
                  }}
                >
                  <img src={HomeIcon} alt="Home Icon" />
                  <span>Home</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    isAuth
                      ? (window.location.href = "/sportsbook/Inplay")
                      : setShowAuthModals(true);
                    // console.log("SideBar", showRightSideBar);
                  }}
                >
                  <img src={InplayIcon} alt="Inplay Icon" />
                  <span>In-play</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    isAuth
                      ? (window.location.href = "/sportsbook/Cricket")
                      : setShowAuthModals(true);
                    // console.log("SideBar", showRightSideBar);
                  }}
                >
                  <img src={CricketIcon} alt="Cricket Icon" />
                  <span>Cricket</span>
                  <div class="sport-event-count badge">
                    {sportsData?.[0]?.values.length}
                  </div>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    isAuth
                      ? (window.location.href = "/sportsbook/Soccer")
                      : setShowAuthModals(true);
                    // console.log("SideBar", showRightSideBar);
                  }}
                >
                  <img src={SoccerIcon} alt="Soccer Icon" />
                  <span>Soccer</span>
                  <div class="sport-event-count badge">
                    {" "}
                    {sportsData?.[1]?.values.length}
                  </div>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    isAuth
                      ? (window.location.href = "/sportsbook/Tennis")
                      : setShowAuthModals(true);
                    // console.log("SideBar", showRightSideBar);
                  }}
                >
                  <img src={TennisIcon} alt=" Icon" />
                  <span>Tennis</span>
                  <div class="sport-event-count badge">
                    {" "}
                    {sportsData?.[2]?.values.length}
                  </div>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    isAuth
                      ? (window.location.href = "/sportsbook/Horse%20Racing")
                      : setShowAuthModals(true);
                    // console.log("SideBar", showRightSideBar);
                  }}
                >
                  <img src={horseracingIcon} alt="horseracing Icon" />
                  <span>Horse</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    isAuth
                      ? (window.location.href = "/sportsbook/Greyhound%20Racing")
                      : setShowAuthModals(true);
                    // console.log("SideBar", showRightSideBar);
                  }}
                >
                  <img src={GreyhoundIcon} alt="Greyhound Racing Icon" />
                  <span>Greyhound</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    isAuth
                      ? (window.location.href = "/sportsbook/Inplay")
                      : setShowAuthModals(true);
                    // console.log("SideBar", showRightSideBar);
                  }}
                >
                  <img src={KabbadiIcon} alt="Greyhound Racing Icon" />
                  <span>Kabbadi</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    isAuth
                      ? (window.location.href = "/casino/spribe/aviator")
                      : setShowAuthModals(true);
                    // console.log("SideBar", showRightSideBar);
                  }}
                >
                  <img src={AvitorImg} alt="Inplay Icon" />
                  <span>Aviator</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    isAuth
                      ? (window.location.href = "/casino/worldcasino")
                      : setShowAuthModals(true);
                    // console.log("SideBar", showRightSideBar);
                  }}
                >
                  <img src={WCIcon} alt="Casino Icon" />
                  <span>World Casino</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    isAuth
                      ? (window.location.href = "/casino/evolution")
                      : setShowAuthModals(true);
                    // console.log("SideBar", showRightSideBar);
                  }}
                >
                  <img src={EvolutionIcon} alt="Casino Icon" />
                  <span>Evolution</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    isAuth
                      ? (window.location.href = "/casino/ezugi")
                      : setShowAuthModals(true);
                    // console.log("SideBar", showRightSideBar);
                  }}
                >
                  <img src={EzugiIcon} alt="Casino Icon" />
                  <span>Ezugi</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    isAuth
                      ? (window.location.href = "/Casino")
                      : setShowAuthModals(true);
                    // console.log("SideBar", showRightSideBar);
                  }}
                >
                  <img src={LCIcon} alt="Casino Icon" />
                  <span>Live Casino</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    isAuth
                      ? (window.location.href = "/Slots")
                      : setShowAuthModals(true);
                    // console.log("SideBar", showRightSideBar);
                  }}
                >
                  <img src={SlotsIcon} alt="Casino Icon" />
                  <span>Slots</span>
                </div>
              </li>
            </ul>
          </li>

          {isAuth && (
            <li className="games_link d-none">
              <h6>My Account</h6>
              <ul className="account-menu">
                <li>
                  <div>
                    <a href="/referral" className="img_size">
                      <img src={refferal} />

                      <span className="bonus-space">Referral</span>
                    </a>
                  </div>
                </li>
                <li>
                  <div>
                    <a href="/cashier">
                      <img src={AcStatement} alt="account statement" />
                      <span>Account Statement</span>
                    </a>
                  </div>
                </li>
                <li onClick={() => dispatch(logoutUser())}>
                  <div>
                    <a href="/">
                      {/* <LogoutIcon /> */}
                      <img src={LogoutIconImg} />
                      <span>Sign Out</span>
                    </a>
                  </div>
                </li>
                <li>
                  <div className="theme-btn d-none">
                    <buton type="button" className="btn" onClick={toggleClass}>
                      <img src={SunLight} alt="sun" className="light_theme" />
                      <img src={MoonLight} alt="moon" className="dark_theme" />
                    </buton>
                  </div>
                </li>
              </ul>
            </li>
          )}
        </ul>
      </div>
      {showAuthModals && (
        <ModalWrapper
          defaultShow={"login"}
          setShowAuthModals={setShowAuthModals}
        />
      )}
    </div>
  );
};

export default MobLeftbar;
