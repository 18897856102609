import React from "react";
import { useNavigate } from "react-router-dom";

import BackIcon from "../../../../assets/svg/BackIcon";
import { MdInfo, MdEmail } from "react-icons/md";
import { CgPhone } from "react-icons/cg";
import { RiWhatsappFill } from "react-icons/ri";
import { RiTelegramFill } from "react-icons/ri";
// import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";

import HomeContact from "../../../../assets/images/home-cont-icon.svg";
import EmailContact from "../../../../assets/images/email-cont-icon.svg";
import PhoneContact from "../../../../assets/images/phone-cont-icon.svg";
import WhatsappContact from "../../../../assets/images/whatsapp-cont-icon.svg";
import ContactImg from "../../../../assets/images/3D-illustration/contact-us-3.png";
import { useSelector } from "react-redux";
import Footer from "../../../../containers/Footer";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Header from "../../../../containers/Header";

const ContactUsBefore = () => {
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const { isAuth } = useSelector((state) => state.auth);

  return (
    <div>
      {isAuth ? <HeaderAfterLogin /> : <Header />}

      <main className="main contact_sec" id="payment_page">
        <div className=" container">
          <div className="pagesHeading">
            <h4>Contact Us</h4>
            <div className="back-link" onClick={() => navigate(-1)}>
              Back <BackIcon />
            </div>
          </div>

          <Row className="align-items-center justify-content-center">
            <Col lg={10} xl={8}>
              <div className="contact_info">
                <Row className="align-items-center">
                  <Col md={6}>
                    <h3 className="heading mb-4">Get In Touch With Us</h3>
                    <ul>
                      <li>
                        <a href="mailto:affiliate@Fox.com">
                          <div className="img_box">
                            <img src={EmailContact} alt="email" />
                          </div>
                          <div>
                            <h6>affiliate@Fox.com</h6>
                          </div>
                        </a>
                      </li>
                      <li>
                        {/* <a href={`tel://${appDetails?.PHONE}`}> */}
                        <a href="8071279842">
                          <div className="img_box">
                            <img src={PhoneContact} alt="phone" />
                          </div>
                          <div>
                            <h6>+91 8071279842</h6>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="https://wa.me/9353444216">
                          <div className="img_box">
                            <img src={WhatsappContact} alt="whatsapp" />
                          </div>
                          <div>
                            <h6>+91 9353444216</h6>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </Col>
                  <Col
                    md={6}
                    className="d-none d-md-flex justify-content-center"
                  >
                    <div className="contact_img">
                      <img src={ContactImg} alt="Contact Image" />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </main>

      {isAuth ? <Footer /> : <BeforeLoginFooter />}
    </div>
  );
};

export default ContactUsBefore;
