import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Container } from "react-bootstrap";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import { slotsData } from "../../../../lib/data/slotsData";
import BackIcon from "../../../../assets/svg/BackIcon";
import { casinoTabs } from "../../../../lib/data";
import CasinoCard from "../../../components/CasinoCard";

const Spribe = () => {
  const navigate = useNavigate();
  const { isAuth } = useSelector((state) => state.auth);
  return (
    <div>
      <HeaderAfterLogin />

      <main className="main mt-4">
        <Container>
          <div className="slots-section SpribePage">
            <div className="pagesHeading">
              <h4>Spribe</h4>
              <a
                href="#"
                className="back-link"
                onClick={() => navigate(-1)}
              >
                Back <BackIcon />
              </a>
            </div>

            <div className="games-section SpribeGames">
              <ul>
                <li>
                  {" "}
                  {Array.isArray(Object.values(casinoTabs?.SpribeGames)) &&
                    Object.values(casinoTabs?.SpribeGames).map((game, idx) => (
                      <CasinoCard key={"casinogame" + idx} data={game} />
                    ))}
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </main>

      <Footer />
    </div>
  );
};

export default Spribe;
