import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import parsePhoneNumber from "libphonenumber-js";
import { sendOtpToMobile } from "../../../redux/auth/actions";
import { useDispatch } from "react-redux";
import { Button, Form } from "react-bootstrap";
// import LoginLeftPart from "../../../assets/images/cricket.gif";
import { APP_CONST } from "../../../config/const";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { Toaster } from "../../../utils/toaster";
import PhoneInput from "react-phone-input-2";
import logo from "../../../assets/svg/logo_new.png";

const MobileNumberModal = ({ show, handleClose, handleModalShow }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const OTP = useSelector((state) => state?.app?.appDetails);
  const { appDetails } = useSelector((state) => state.app);
  const [searchParams, setSearchParams] = useSearchParams();
  const [referalCode, setReferalCode] = useState(searchParams.get("refferal"));
  const [agentCode, setAgentCode] = useState(searchParams.get("agent_code"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = () => {
    setIsSubmitting(true);
    const phoneNumber = parsePhoneNumber("+" + mobileNumber);
    let payload = {};
    if (phoneNumber) {
      payload.mobile = phoneNumber.nationalNumber;
      payload.country_code = phoneNumber.countryCallingCode;
    }

    dispatch(sendOtpToMobile(payload))
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.error === false) {
          persistDetails(payload);

          if (OTP && OTP?.OTP === 0) {
            // navigate("/verify-number", {
            //   state: { referalCode, agentCode },
            // });
            handleClose(APP_CONST.AUTH_MODAL.MOBILE_NUMBER, "auto");
            handleModalShow(APP_CONST.AUTH_MODAL.VERIFY_NUMBER);
          } else {
            // navigate("/register", {
            //   state: { referalCode, agentCode },
            // });
            handleClose(APP_CONST.AUTH_MODAL.MOBILE_NUMBER, "auto");
            handleModalShow(APP_CONST.AUTH_MODAL.REGISTER);
          }
        } else {
          Toaster(APP_CONST.TOAST_TYPE.ERROR, res?.data?.message);
        }
      })
      .catch((err) => {
        Toaster(APP_CONST.TOAST_TYPE.ERROR, err?.response?.data?.message);
        setIsSubmitting(false);
      });
  };

  const persistDetails = (data) => {
    localStorage.setItem("mobile_details", JSON.stringify(data));
  };
  return (
    <Modal
      show={show}
      className={"steps-canvas login-modal"}
      onHide={() => {
        console.log("working");
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      placement={"end"}
    >
      <Modal.Header
        closeButton
        onClick={() =>
          handleClose(APP_CONST.AUTH_MODAL.MOBILE_NUMBER, "manual")
        }
        closeVariant="black"
      >
        {/* <Modal.Title>Verify Number</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <div className="login-section">
          <div className="ModalSection">
            <div className="Modal-right-part">
              {/* <img src={appDetails?.LOGO_URL} alt="Logo" /> */}
              <div className="form-steps">
                {" "}
                <Modal.Title>New to Fox?</Modal.Title>
                <h3>Sign up now, it's easy!</h3>
                <h4>Please enter your 10 digit mobile number to get an OTP</h4>
                <Form>
                  <Form.Group
                    className="mb-3 position-relative"
                    controlId="formBasicPassword"
                  >
                    <PhoneInput
                      className="PhoneInput"
                      country={"in"}
                      countryCodeEditable={false}
                      inputProps={{
                        name: "phone",
                        required: true,
                      }}
                      onlyCountries={["in"]}
                      inputStyle={{}}
                      onChange={(phone) => {
                        // alert("phone", phone);
                        // alert(phone);
                        setMobileNumber(phone);
                      }}
                    />
                  </Form.Group>

                  <Button
                    variant="primary"
                    className="loginbtn"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Loading.." : "Next"}
                  </Button>
                </Form>
              </div>
              <div className="login-section">
                {/* <div className="login-leftPart">
            <img src={LoginLeftPart} />
          </div> */}
                <div className="Login-rightPart">
                  {/* <Modal.Title>Verify Number</Modal.Title> */}
                  <div className="form-steps">
                    <div className="sign-up mt-4">
                      <a
                        href="#"
                        onClick={() => {
                          handleClose(
                            APP_CONST.AUTH_MODAL.MOBILE_NUMBER,
                            "auto"
                          );
                          handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                        }}
                      >
                        Already have an account? Sign In here
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default MobileNumberModal;
