import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import EvolutionDownload from "../assets/images/icons/footer/evolution.png";
import EzugiDownload from "../assets/images/icons/footer/ezugi.png";
import PragamaticDownload from "../assets/images/icons/footer/pragmatic-live.png";
import betgamesDownload from "../assets/images/icons/footer/betgames.png";
import superspadeDownload from "../assets/images/icons/footer/ssg.png";
import BetsoftDownload from "../assets/images/icons/footer/betsoft.png";
import spribeDownload from "../assets/images/icons/footer/spribe.png";
import evoplayDownload from "../assets/images/icons/footer/Evoplay.png";
import plusDownload from "../assets/images/icons/footer/logo-18plus.svg";

import AppDownload from "../assets/images/banner/appdownload.jpg";

import BottomNav from "./BottomNav";

const Footer = () => {
  const { appDetails } = useSelector((state) => state.app);
  const navigate = useNavigate();

  return (
    <>
      <footer className="footer">
        <div className="AppDownloadSection">
          <a
            href="https://dl.dropbox.com/scl/fi/f5xig1v0fdkgzoltw2hgg/fox246.apk?rlkey=0wvdanvzrnxetd0gwuvo515yp&dl=0"
            download="https://dl.dropbox.com/scl/fi/f5xig1v0fdkgzoltw2hgg/fox246.apk?rlkey=0wvdanvzrnxetd0gwuvo515yp&dl=0"
            target="__blank"
          >
            <img src={AppDownload} alt="AppDownload" />
          </a>
        </div>

        <div className="ProviderSection">
          <ul>
            <li>
              <img src={EvolutionDownload} alt="AppDownload" />
            </li>
            <li>
              <img src={EzugiDownload} alt="AppDownload" />
            </li>

            <li>
              <img src={PragamaticDownload} alt="AppDownload" />
            </li>
            <li>
              <img src={betgamesDownload} alt="AppDownload" />
            </li>
            <li>
              <img src={superspadeDownload} alt="AppDownload" />
            </li>
            <li>
              <img src={BetsoftDownload} alt="AppDownload" />
            </li>
            <li>
              <img src={spribeDownload} alt="AppDownload" />
            </li>
            <li>
              <img src={evoplayDownload} alt="AppDownload" />
            </li>
            <li>
              <img src={plusDownload} alt="AppDownload" />
            </li>
          </ul>

          <p>
            You must be over 18 years old, or the legal age at which gambling or
            gaming activities are allowed under the law or jurisdiction that
            applies to you. You must reside in a country in which access to
            online gambling to its residents.
          </p>
        </div>

        <BottomNav />
      </footer>
    </>
  );
};

export default Footer;
